<template>
  <article
    class="game-card"
    data-t="game-card-m"
    :data-title="game.name"
    :data-game-id="game.gameId"
    :class="cardClasses"
  >
    <NuxtI18nLink
      :to="`/casino/games/${game.gameId}`"
      class="game-card-link"
      data-t="game-card-link-p7h7"
    >
      <div v-if="labels.length" class="labels">
        <StLabel
          v-for="label in labels"
          :key="label.code"
          :label="label.name"
          data-t="game-label-ld21"
          :bg-color="label.color"
        />
      </div>

      <div class="game-card-image" data-t="game-card-image-o11h">
        <img :src="game.image" alt="" width="140" loading="lazy" />
        <div v-if="notAvailableInRegion" class="img-content">
          {{ t('casino.notAvailableInRegion') }}
        </div>
        <MGameCardAnimation
          :image="game.image"
          :game-id="game.gameId"
          :is-animated="isAnimated"
        />
      </div>
      <div class="game-card-info">
        <div class="game-card-name" data-t="game-card-image-su0q">
          <div class="game-title" data-t="game-name-m">{{ game.name }}</div>
          <div class="game-provider" data-t="game-provider-m">
            {{ game.provider }}
          </div>
        </div>
        <div v-if="showPlayers" class="players-info">
          <div class="card-badge">
            <StLiveIndicator color="orange" />
            <span class="card-badge-label">{{ game.playersCount }}</span>
          </div>
          <span class="players">{{ t('gameCard.plays') }}</span>
        </div>
      </div>
    </NuxtI18nLink>
    <FavoriteButton
      v-if="isFavoriteButtonVisible"
      :id="game.gameId"
      class="favorite-button"
      data-t="favorite-icon-wrapper-o21x"
      mobile
    />
    <div v-if="isLounchingGame" class="loader">
      <StSpinner size="16" />
    </div>
  </article>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import type { Game } from '../../types'
import useGameCard from './useGameCard'
import FavoriteButton from './parts/FavoriteButton.vue'
import MGameCardAnimation from './MGameCardAnimation.vue'
import { useGameOpenAnimation } from '../../composables/useGameOpenAnimation'

const { isAuthenticated } = storeToRefs(useUserStore())

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    game: Game
    showPlayerCount?: boolean
  }>(),
  {
    showPlayerCount: true,
  },
)

const { game, showPlayerCount } = toRefs(props)
const { labels, showPlayers, notAvailableInRegion } = useGameCard(
  game,
  showPlayerCount,
)

const { isLounchingGame, isAnimated } = useGameOpenAnimation(
  toRef(() => game.value.gameId),
)

const isFavoriteButtonVisible = computed(
  () => isAuthenticated.value && !isLounchingGame.value && !isAnimated.value,
)

const cardClasses = computed(() => ({
  loading: isLounchingGame.value,
  animated: isAnimated.value,
}))
</script>

<style scoped>
.game-card-link {
  color: inherit;
  text-decoration: none;
}

.game-card-image {
  position: relative;
  z-index: 1;

  aspect-ratio: 3/2;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);

  img {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: var(--border-radius-100);
  }
}

.game-card-info {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-050);
  justify-content: space-between;

  padding: var(--spacing-075) var(--spacing-050) 0 0;

  white-space: nowrap;
}

.game-card-name {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  gap: var(--spacing-025);

  width: 100%;
  min-width: 0;
}

.game-title {
  overflow: hidden;
  font: var(--mobile-text-semibold);
  color: var(--text-primary);
  text-overflow: ellipsis;
}

.game-provider {
  overflow: hidden;
  font: var(--mobile-caption-2-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
}

.players-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: flex-end;
}

.players {
  font: var(--mobile-caption-2-medium);
  color: var(--text-tertiary);
}

.card-badge {
  display: flex;
  align-items: center;
}

.card-badge-label {
  font: var(--mobile-caption-2-medium);
}

.labels {
  position: absolute;
  z-index: 2;
  top: 6px;
  left: -4px;
  transform: translateX(0);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: flex-start;

  opacity: 1;

  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}

.img-content {
  position: absolute;
  z-index: 1;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: calc(var(--spacing-050) * -1);
  padding: 0 var(--spacing-200);

  font: var(--desktop-text-sm-semibold);
  text-align: center;

  background-color: #000000a3; /* stylelint-disable-line */
  backdrop-filter: blur(2px);
  border-radius: var(--border-radius-100);
}

.loader {
  position: absolute;
  z-index: 2;
  top: var(--spacing-075);
  right: var(--spacing-075);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
}

.game-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.loading {
    .game-card-image img {
      opacity: 0.66;
    }
  }

  &.animated {
    .labels {
      opacity: 0;
    }
  }
}
</style>
